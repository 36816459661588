import axios from "axios";
import { store } from "../store/store";
import { getResponseMessage } from "../assets/js/utils";

export default {
  async getStores(area) {
    return axios
      .get(`/restaurant/area/like?areaName=${area}`)
      .then(response => { return response.data })
      .catch(error => console.log(error.response))
  },

  async getStoreReviews(storeId, page) {
    return axios
      .get(`/review/store/${storeId}?page=${page}&resultsPerPage=15`)
      .then(response => { return response.data })
      .catch(error => console.log(error.response))
  },

  async getStoreByTableIdAndService(tableId, service) {
    let result = await axios
      .get(`/store?tableId=${tableId}&departmentName=${service}`)
      .catch((error) => console.log(error.response));

    return result.data;
  },

  async getStoreById(id) {
    return axios
      .get(`/store/${id}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getTable(tableId) {
    return axios
      .get(`/restaurant/table?tableId=${tableId}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getOrderLimit(restaurantId) {
    return axios
      .get(`/restaurant/order-limit?restaurantId=${restaurantId}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getListOfRooms(storeId, isStoreSplitInSections, tableSection, setResponseAsTables) {
    let sectionParameter = ""

    if (isStoreSplitInSections) {
      sectionParameter = `&section=${tableSection}`
    }

    return axios
      .get(`/store/hotel-rooms?storeId=${storeId}${sectionParameter}`)
      .then(response => {
        if (setResponseAsTables) {
          store.dispatch("setAvailableTables", response.data);
        }
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async getChargingRooms(storeId) {
    return axios
      .get(`/store/hotel-rooms?storeId=${storeId}`)
      .then(response => {
        store.dispatch("setAvailableChargingRooms", response.data);
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async getListOfAvailableTables(storeId, fetchTablesPerSection, restaurantId, tableType, tableSection) {

    let sectionParameter = ""

    if (fetchTablesPerSection) {
      sectionParameter = `&section=${tableSection}`
    }

    return axios
      .get(`/store/tables?storeId=${storeId}&restaurantId=${restaurantId}&type=${tableType}${sectionParameter}`)
      .then(response => {
        store.dispatch("setAvailableTables", response.data);
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async getAllAvailableTablesForRestaurant(storeId, restaurantId, section) {
    return axios
      .get(`/store/all-tables?storeId=${storeId}&restaurantId=${restaurantId}&section=${section}`)
      .then(response => {
        store.dispatch("setAvailableTables", response.data);
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async getTablesWithOpeningsForRestaurant(storeId, restaurantId) {
    return axios
      .get(`/store/all-tables-with-openings?storeId=${storeId}&restaurantId=${restaurantId}`)
      .then(response => {
        store.dispatch("setTablesWithOpenings", response.data);
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async getAllAvailableTablesAndPackagesOfStore(storeId, section) {
    return axios
      .get(`/store/tables-and-packages?storeId=${storeId}&section=${section}`)
      .then(response => {
        store.dispatch("setAvailableTables", response.data);
        return response.data
      })
      .catch((error) => console.log(error.response));
  },

  async getRestaurantsWithTableOpenings(storeId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/restaurant/of-type-table-opening?storeId=${storeId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getRestaurantsWithRoomOpenings(storeId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/restaurant/of-type-room-opening?storeId=${storeId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getTableSections(storeId, setAsTableFamilySections) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/store/sections/tables?storeId=${storeId}`, {
        headers: {
        },
      })
      .then((response) => {
        if (setAsTableFamilySections) {
          store.dispatch("setTableFamilySections", response.data);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getHotelRoomSections(storeId) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/store/sections/hotel-rooms?storeId=${storeId}`, {
        headers: {
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getTableOpeningsForRestaurant(restaurantId, tableTypes, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/table-opening/v3/fetch-open?restaurantId=${restaurantId}&tableTypes=${tableTypes}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getTablesOfRestaurants(storeId, restaurantIds, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/store/tables-of-restaurants?storeId=${storeId}&restaurantIds=${restaurantIds}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async closeTableOpeningsForRestaurant(restaurantId, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/close-table?restaurantId=${restaurantId}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async transferTableOpening(tableOpeningId, tableToTransferId, restaurantId, restaurantIdToTransferTable, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/waiter/tranfer-table/${tableOpeningId}?tableToTransferId=${tableToTransferId}&restaurantId=${restaurantId}&restaurantIdToTransferTable=${restaurantIdToTransferTable}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async payPartialBeforeClose(restaurantIds, payload, publishAndReceipt, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/pay-partial-before-close?restaurantIds=${restaurantIds}&publishAndReceipt=${publishAndReceipt}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async generateTempReceiptForOrder(orderId, restaurantId) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/generate-temp-receipt-for-order?orderId=${orderId}&restaurantId=${restaurantId}`, null, {
        headers: {
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async generateFinalReceiptForOrder(orderId, restaurantId) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/generate-final-receipt-for-order?orderId=${orderId}&restaurantId=${restaurantId}`, null, {
        headers: {
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async sendTempReceiptForPrint(receiptId, restaurantId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .post(`${adminBaseUrl}/table-opening/send-temp-receipt-for-print?receiptId=${receiptId}&restaurantId=${restaurantId}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async sendFinalReceiptForPrint(receiptId, restaurantId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .post(`${adminBaseUrl}/table-opening/send-final-receipt-for-print?receiptId=${receiptId}&restaurantId=${restaurantId}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async fetchTempReceiptsForTableOpening(tableOpeningId, restaurantId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/table-opening/get-temp-receipts?tableOpeningId=${tableOpeningId}&restaurantId=${restaurantId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async fetchFinalReceiptsForTableOpening(tableOpeningId, restaurantId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/table-opening/get-final-receipts?tableOpeningId=${tableOpeningId}&restaurantId=${restaurantId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async generateTempReceipt(restaurantId, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .post(`${adminBaseUrl}/table-opening/generate-temp-receipt?restaurantId=${restaurantId}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async generateFinalReceipt(restaurantId, payload, isSelfDeliveryReceipt, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .post(`${adminBaseUrl}/table-opening/generate-final-receipt?restaurantId=${restaurantId}&isSelfDeliveryReceipt=${isSelfDeliveryReceipt}&paymentType=${payload.paymentType}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async generateTempSelfDeliveryReceipt(restaurantId, tableOpeningId, amount, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    let amountFixed = Number(amount).toFixed(2);
    return axios
      .post(`${adminBaseUrl}/table-opening/generate-temp-self-delivery-receipt?restaurantId=${restaurantId}&tableOpeningId=${tableOpeningId}&amount=${amountFixed}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async generateFinalReceiptFromTemps(receiptIds, restaurantId, paymentType, isSelfDeliverReceipt, invoiceDetailsRequest, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    let isInvoice = invoiceDetailsRequest != null;
    return axios
      .post(`${adminBaseUrl}/table-opening/generate-final-receipt-from-temps?receiptIds=${receiptIds}&restaurantId=${restaurantId}&paymentType=${paymentType}&isInvoice=${isInvoice}&isSelfDeliverReceipt=${isSelfDeliverReceipt}`, invoiceDetailsRequest, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async fetchCompanyByVat(restaurantId, vat, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/table-opening/company-details?restaurantId=${restaurantId}&vat=${vat}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async cancelTempReceipts(receiptIds, restaurantId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .post(`${adminBaseUrl}/table-opening/cancel-temp-receipts?receiptIds=${receiptIds}&restaurantId=${restaurantId}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async updateReceiptToCardPayment(receiptId, restaurantId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/update-receipt-to-card-payment/${receiptId}?restaurantId=${restaurantId}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },


  async chargeToRoom(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/charge-to-room?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async partialTransfer(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/partial-transfer?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async cancelProducts(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/cancel-products?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async treatProducts(restaurantIds, payload, publishAndReceipt, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/treat-products?restaurantIds=${restaurantIds}&publishAndReceipt=${publishAndReceipt}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async reOrder(restaurantIds, payload, language, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/repeat-order?restaurantIds=${restaurantIds}&language=${language}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {

        let message = {
          GRE: "",
          ENG: "",
        };

        if (error.response) {
          console.log(error.response)
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε ξανά";
          message.ENG = "Something went wrong, try again";
        }

        return {
          error: true,
          message: message,
        };

      });
  },
};
